export class UserLoginDetails
{
     UserName :string;
     Password :string;
     GRecaptchaResponse :string;
     PanelName:string;
     UserOtp: string;
     IsResendVerification: boolean;
     IsResendCount: number;
     IsTwofactorAuthenticationEnabled: boolean;
}