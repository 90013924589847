import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LocalizationService } from '../../../services/localization.service';
import { Router } from '@angular/router';
import { SignInService } from '../../../services/signin.service';
import { Title } from '@angular/platform-browser';
import { ResetPassword } from '../../../model/Request/ResetPassword';
import { IResponse } from '../../../model/Response/IResponse';
import { ReCaptcha2Component } from 'ngx-captcha';
import { FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { SurveyService } from '../../../services/survey/survey.service';
import { UserLogInfo } from '../../../model/UserInfo';
import { environment } from 'src/environments/environment';
import { UserStatus } from '../../../model/UserLoginResult';
import { PopupComponent } from '../../../Common/popup/popup.component';
import { MatDialog } from '@angular/material/dialog';
import { SetPassword } from '../../../model/Request/SetPassword';
import { MyProfileService } from '../../../services/myProfile.service';
import { UserIdleService } from 'angular-user-idle';
import { String } from 'typescript-string-operations';
import { AppConfig } from 'src/app/Config/AppConfig';

const pageName = 'setPassword';
const localizationSpinner = 'localizationSpinner';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.css']
})
export class SetPasswordComponent implements OnInit, OnDestroy {
  localModel: any;
  lang: string = 'en';
  toggleLen: boolean = false;
  toggleUp: boolean = false;
  toggleLow: boolean = false;
  toggleNum: boolean = false;
  toggleSpch: boolean = false;
  value: string;
  type: string;
  toggle: boolean = false;
  show: boolean = false;
  retype: boolean = false;
  setPasswordData: ResetPassword;
  response: IResponse;
  showValidationMessages: boolean = false;
  isUserResetLinkExpired: boolean = false;
  isSuccess: boolean = false;
  passwordPolicy: string;
  isPasswordPolicy: boolean = false;
  isFailed: boolean = false;
  isCombinationExists: boolean;
  isInvalid: boolean;
  showCaptchaValidation: boolean = false;
  sitekey: string;
  screenwidth: number;
  public theme: 'light' | 'dark' = 'light';
  public size: string;
  public gtype: 'image' | 'audio';
  showCaptchaValidationMessages: boolean = false;
  @ViewChild('captchaElem', { static: true }) recaptcha: ReCaptcha2Component
  showResetForm: boolean = false;
  userData: UserLogInfo;
  isSessionTimedOut: boolean = false;
  pageExpiryInSeconds: number = 900;
  timeRemainingString: string = '--';
  timerLocalString: string;
  pageTimeoutMsg: string;
  logoutTimer: any;
  constructor(private localizationService: LocalizationService,
    private router: Router,
    private signinService: SignInService,
    private profileService: MyProfileService,
    private titleService: Title,
    private spinner: NgxSpinnerService,
    private surveyService: SurveyService,
    private dialog: MatDialog,
    private usrIdle: UserIdleService,
    private appConfig: AppConfig) {
    this.localizationService.removeLocalStorageVariable('currentuser');
    this.localizationService.removeLocalStorageVariable('restrict');
    this.localizationService.removeLocalStorageVariable('header');
    this.localizationService.removesessionStorageVariable('loginresult');
    this.localizationService.removesessionStorageVariable('myProfile');
    this.localModel = this.localizationService.getLocalization();

    this.pageExpiryInSeconds = appConfig.SetPasswordPageExpiryInSeconds;
    if (localStorage.getItem("SiteValue") !== null) {
      this.sitekey = this.localizationService.decryptValue(localStorage.getItem("SiteValue"));
    }

    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
      this.timerLocalString = this.localModel?.lbl_PageWillExpire_Msg;
      this.updatePageExpiryLabel();
      this.lang = this.localizationService.getlocalizationfromSession();
      this.lang = this.lang == 'english' || this.lang == null ? 'en' : 'es';
    });
  }
  ngOnDestroy(): void {
    this.logoutTimer && clearInterval(this.logoutTimer);
  }

  ngOnInit(): void {

    this.screenwidth = window.innerWidth;
    this.size = this.screenwidth <= 400 ? "compact" : "normal";

    // Spinner to show if localization is not loaded
    this.spinner.show(localizationSpinner);
    const localizationChecker = setInterval(() => {
      if (this.localModel && this.sitekey) {
        this.updatePageExpiryLabel();
        this.spinner.hide(localizationSpinner);
        clearInterval(localizationChecker);
      }
      else {
        this.localModel = this.localizationService.getLocalization();
        if (localStorage.getItem("SiteValue") !== null) {
          this.sitekey = this.localizationService.decryptValue(localStorage.getItem("SiteValue"));
        }
      }
    }, 1000);

    const qualtricsToken = sessionStorage.getItem('qToken');
    const surveyId = sessionStorage.getItem('qSurveyId');
    const responseId = sessionStorage.getItem('qResponseId');

    // Redirect to root if no token found for this page
    if (!qualtricsToken) {
      this.router.navigate(['/']).catch(() => { });
      return;
    }

    // remove token from session storage
    sessionStorage.removeItem('qToken');
    sessionStorage.removeItem('qSurveyId');
    sessionStorage.removeItem('qResponseId');

    this.getLoginInfoByToken(qualtricsToken, surveyId, responseId);

    this.titleService.setTitle(this.localModel?.lbl_home_title);
    this.localizationService.titleChanged.subscribe(() => {
      this.titleService.setTitle(this.localModel?.lbl_home_title)
    });
  }

  onkeyUp(event) {
    this.value = event.target.value;
    let array: string[] = [];
    document.querySelector("[data-check=length]").className = this.value.length >= 7 ? "met" : "not-met";
    array.push(document.querySelector("[data-check=length]").className);

    this.passwordPolicy = document.querySelector("[data-check=uppercase]").className = /[A-Z]/.exec(this.value) ? "met" : "not-met";
    array.push(document.querySelector("[data-check=uppercase]").className);

    this.passwordPolicy = document.querySelector("[data-check=lowercase]").className = /[a-z]/.exec(this.value) ? "met" : "not-met";
    array.push(document.querySelector("[data-check=lowercase]").className);

    this.passwordPolicy = document.querySelector("[data-check=number]").className = /\d/.exec(this.value) ? "met" : "not-met";
    array.push(document.querySelector("[data-check=number]").className);

    this.passwordPolicy = document.querySelector("[data-check=specialcharacter]").className = /[!@#$%^&*~`()_+\-=[\]{};':"\\|,.<>/?]+/.exec(this.value) ? "met" : "not-met";
    array.push(document.querySelector("[data-check=specialcharacter]").className);
    if (array.filter(policy => policy == "not-met").length > 0) {
      this.isPasswordPolicy = false;
    } else {
      this.isPasswordPolicy = true;
    }
  }

  setTimerExpiry() {
    this.logoutTimer = setInterval(() => {
      this.pageExpiryInSeconds--;
      if (this.pageExpiryInSeconds <= 0) {
        clearInterval(this.logoutTimer);
        this.signinService.logout();
      }
      else {
        this.timeRemainingString = 
        `${Math.floor(this.pageExpiryInSeconds/60).toString().padStart(2, '0')}:${(this.pageExpiryInSeconds%60).toString().padStart(2, '0')}`;
        this.updatePageExpiryLabel();
      }
    }, 1000);
  }

  getLoginInfoByToken(qualtricsToken, surveyId, responseId) {
    this.spinner.show(pageName);
    this.signinService.getLoginInfoByAuthToken(qualtricsToken).subscribe(res => {
      if (res?.Data?.UserDetails && res.Data.UserDetails.UserLoginResult == UserStatus.NeedPassReset) {
        this.setTimerExpiry();
        this.userData = res.Data.UserDetails;
        this.userData.Localization = res.Data.UserDetails.Localization == 1 ? "en-US" : "es-MX";
        this.userData.panelname = environment.PanelName;
        this.localizationService.setViewHelpCenterSession('true');
        this.localizationService.setCurrentUserVariable(this.userData);
        this.showResetForm = true;
        let userLang = (this.userData.Localization === "en-US" || this.userData.Localization === "en") ? "english" : "spanish";
        this.localizationService.changeLocalization(userLang);
        // Capture Survey Response if token validation returns NeedPassReset status.
        //this.surveyService.captureSurveyResponse(surveyId, responseId).subscribe(() => { });
      }
      else {
        // No User data found or user has already reset their password or link is expired/invalid.
        sessionStorage.setItem("LinkExpiredMessage", "1");
        this.router.navigate(['/login'], { queryParams: {} }).catch(() => { });
        this.showResetForm = false;
      }
    }, () => {
      this.showResetForm = false;
      this.router.navigate(['/login'], { queryParams: {} }).catch(() => { });
    }, () => {
      this.spinner.hide(pageName);
    });
  }

  onTogglePasswordClick() {
    this.show = !this.show;
  }

  onToggleRetypePassClick() {
    this.retype = !this.retype
  }

  async onSubmitClick(setPasswordForm: FormGroup) {
    this.spinner.show(pageName);
    if (setPasswordForm.invalid) {
      this.spinner.hide(pageName);
      return;
    }

    if (setPasswordForm.value.recaptcha === "" || setPasswordForm.value.recaptcha === undefined) {
      this.showCaptchaValidationMessages = true;
      this.showCaptchaValidation = true;
      this.scrollToTheTop();
      this.spinner.hide(pageName);
      return;
    }

    let setPasswordData = new SetPassword();
    setPasswordData.NewPassword = setPasswordForm.value.newPassword;
    setPasswordData.Captcha = setPasswordForm.value.recaptcha;
    setPasswordData.EmailAddress = this.userData.UserName;
    setPasswordData.MNO = this.userData.MNo;
    setPasswordData.PanelMemberId = this.userData.PanelMemberId;
    await this.callSetPassword(setPasswordData);
  }

  async callSetPassword(data: SetPassword) {
    this.spinner.show(pageName);
    let res = await this.profileService.setProfilePassword(data).catch(() => {
      this.showValidationMessages = true;
      this.isInvalid = true;
      this.scrollToTheTop();
      this.spinner.hide(pageName);
    });
    let passwordChanged = false;
    if (!res || res?.Code == '105') {
      this.showPopup(this.localModel.lbl_Popup_Heading_Failure, this.localModel.lbl_Invalid_MNO);
    }
    else if (res?.Data?.UpdateStatus) {
      switch (res.Data.UpdateStatus.Status) {
        // Password updated successfully
        case 0:
          this.usrIdle.stopWatching();
          passwordChanged = true;
          this.localizationService.removesessionStorageVariable('loginresult');
          this.profileService.isPartiallyCreatedProfileSub.next(this.userData.isPartiallyCreated);
          this.router.navigate(['myprofile'], { fragment: 'google-screenwise-meter-account' }).catch(() => { });

          break;
        // Show combination exists message for both Same new password and temp password and Combination exists 
        case 2:
        case 3:
          this.isCombinationExists = true;
          this.showValidationMessages = true;
          this.isSuccess = false;
          this.showValidationMessages = true;
          this.scrollToTheTop();
          break;
        default:
          this.isSuccess = false;
          this.showValidationMessages = true;
          this.scrollToTheTop();
          break;
      }
    }

    if (passwordChanged) {
      this.usrIdle.setConfigValues({ idle: environment.SessionTimeout, timeout: 1, ping: 0 })
      this.usrIdle.startWatching();
      this.signinService.userLoginChanged.next();
    }
    this.spinner.hide(pageName);
  }

  onClose(form: FormGroup) {
    this.showValidationMessages = false;
    this.recaptcha.resetCaptcha();
    form.reset();
  }

  showPopup(heading: string, message: string) {
    this.dialog.open(PopupComponent, {
      disableClose: true,
      data: {
        header: heading,
        content: message
      }
    });
  }

  onCloseSection() {
    this.showCaptchaValidationMessages = false;
    this.recaptcha.resetCaptcha();
  }

  scrollToTheTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
  onCancelClick() {
    this.localizationService.removeLocalStorageVariable('currentuser');
    this.localizationService.removeLocalStorageVariable('restrict');
    this.localizationService.removeLocalStorageVariable('header');
    this.localizationService.removesessionStorageVariable('loginresult');
    this.localizationService.removesessionStorageVariable('myProfile');
    location.replace('/home');
  }

  updatePageExpiryLabel() {
    this.timerLocalString = String.Format(this.localModel?.lbl_PageWillExpire_Msg, this.timeRemainingString);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenwidth = window.innerWidth;
    this.size = this.screenwidth <= 400 ? "compact" : "normal";
  }
}
