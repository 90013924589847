import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SignInService } from 'src/app/services/signin.service';
import { LocalizationService } from 'src/app/services/localization.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserLogInfo } from 'src/app/model/UserInfo'
import { MyProfileService } from 'src/app/services/myProfile.service';
import { MyProfileModel } from 'src/app/model/myProfile.model';
import { LanguageValue } from 'src/app/model/LanguageValue';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/services/Notifications/notification.service';
import { NotificationsInfoModel } from 'src/app/model/notificationInfo.model';
import { ChatbotComponent } from '../chatbot/chatbot.component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers:[ChatbotComponent]
})

export class HeaderComponent implements OnInit {
  @ViewChild('profileMenu') profileMenu:ElementRef;
  isUserSignIn:boolean;
  toggle:boolean = false;
  togglenotification:boolean = false;
  localModel : any;
  userData:UserLogInfo = new UserLogInfo();
  isChangeLanguageDropDown :boolean;
  myProfile : MyProfileModel;
  notificationHeader: string;
  notificationMsg: string; 
  showExtraNotificationBtn: boolean;
  selected1: any;
  showNotification: boolean;
  mno: number;
  panelId: number;
  appName: string;
  notificationCount: number=0;
  unreadNotifications: NotificationsInfoModel[];
  loadingImg: boolean = true;
  loadingImg1:boolean = false;
  showNotificationPopup: boolean;
  noNotifications:boolean;
  notificationData: NotificationsInfoModel[];
  Langs: LanguageValue[] ;
  sanitizedNotificationMsg:SafeHtml;

  constructor(private signInService:SignInService,private route:Router,    private chatBot: ChatbotComponent,
    private localizationService:LocalizationService,private spinnerSrv: NgxSpinnerService,private myProfileService: MyProfileService,private notificationService:NotificationService,private sanitizer: DomSanitizer) { 
      this.localModel = this.localizationService.getLocalization();
      this.Langs= this.localizationService.getLanguageArray();
      this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
    });
    this.userData=this.localizationService.getCurrentUserVariable();
 
    if(this.userData!==null){
      this.mno=this.userData.MNo;  
      this.getNotifications();
    }
  }
  moblieMenu: boolean = false;

  
 
  ngOnInit(): void {

    this.getLanguagefromSession();
    this.isChangeLanguageDropDown = environment.LanguageChangeDropDown;
    this.myProfileService.myProfileChanged.subscribe(res => {
      this.myProfile = res;
      this.userData.FirstName=this.myProfile.FirstName;
      this.userData.LastName=this.myProfile.LastName;     
    });
    this.signInService.profiletogleChanged.next();   
  
    this.userData = JSON.parse(localStorage.getItem("currentuser"));
    this.signInService.profiletogleChanged.next();  
   this.userData=this.localizationService.getCurrentUserVariable();
      
    this.spinnerSrv.show();
    this.localModel = this.localizationService.getLocalization();
    this.isUserSignIn = this.signInService.isSignIn();


    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;   
      this.getLanguagefromSession();
    });

    this.signInService.userLoginChanged.subscribe(res => {
      this.isUserSignIn = this.signInService.isSignIn();
      this.getLanguagefromSession();
      this.showNotificationPopup=false;
      this.onToggle;
     
      this.userData = this.localizationService.getCurrentUserVariable();  
      if( this.userData!=null ){
        this.getNotifications(); 
      }    
    })

    this.signInService.profiletogleChanged.subscribe(res=>{
      this.toggle=this.signInService.profileChanged();
      if(this.togglenotification==true){
            this.togglenotification=this.signInService.notificationChanged();
           this.onToggle();
      }    
    });

    this.notificationService.notificationRead.subscribe(res=>{
      this.getNotifications();
    })
  // this.spinnerSrv.hide(); 
      // setTimeout(() => {
      //   this.spinnerSrv.hide();  
      // }, 1000);
   }
  
  
  
  onProfile(dd:any){
    this.toggle = !this.toggle;
    this.togglenotification=this.signInService.notificationChanged();
    this.onToggle();
  }

  onMyProfile(){
    this.toggle = !this.toggle;   
    this.route.navigate(["/myprofile"]);
  }

  onImportNofication(){   
    
    if(sessionStorage.getItem("notifications") !== null){
      this.unreadNotifications = JSON.parse(sessionStorage.getItem('notifications')).filter(x => x.msgmbrlistid);     
    }

    this.showNotificationPopup=false;
    this.togglenotification = !this.togglenotification;
    this.toggle=this.signInService.profileChanged();
    
    if(this.togglenotification==false){
      this.onToggle();
    }
    if(this.loadingImg1 === false && this.togglenotification){
      const notificationLink = document.querySelector(".icon--utility");
      notificationLink.classList.add("active");
      this.loadingImg=false;
      this.loadingImg1=true;
      }

    this.notificationService.notification.subscribe(res=>{
      var iconchanged=this.signInService.notificationChanged();
      if(this.loadingImg1 == true && iconchanged != null){
       this.onToggle();
      }
      });
  
  }

  onToggle(){
    const notificationLink = document.querySelector(".icon--utility");
      notificationLink.classList.remove("active");
      this.loadingImg=true;
      this.loadingImg1=false;
  }

  onSignOut(){
    this.showNotificationPopup=false
    this.toggle = !this.toggle;
    this.chatBot.CloseChatWindow();
    this.signInService.logout();
  }

  clickEvent(){
    //if(this.moblieMenu)
    //{
      this.moblieMenu = !this.moblieMenu;    
      if(this.toggle === true){
        this.toggle = !this.toggle;
      }
      if(this.togglenotification===true){
        this.togglenotification=!this.togglenotification
        this.onToggle();
      }
    //}  
  }

  onLanguageChange(event){
    this.selected1=event
    const newVal = event==1?'english':'spanish';   
    this.localizationService.changeLocalization(newVal);
    
  }

  checkRoute(){
    if(this.isUserSignIn){
      this.route.navigate(["/dashboard"])
    }else{
      this.route.navigate(["/"])
    }
  }

  openNotificationMsg(id: number){
    this.onToggle();
    var notificationData=JSON.parse(sessionStorage.getItem('notifications')).filter(x => x.msgmbrlistid==id);
    const sanitizedContent = this.sanitizeHtml(notificationData[0].msgcontent);

    this.showNotificationMsg(notificationData[0].msgtitle,sanitizedContent,false)
    this.notificationService.setReadNotificationFlag(id);
  }

  sanitizeHtml(content: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }
  private showNotificationMsg( title:string,message: SafeHtml, showExtraBtn: boolean) {
    this.togglenotification = !this.togglenotification;
    this.showNotificationPopup=true;
    this.notificationHeader = title;
    this.sanitizedNotificationMsg = message;
    this.showExtraNotificationBtn = showExtraBtn;
  }
  private getNotifications() {

    this.notificationService.getNotifications(this.userData.MNo).subscribe((responseData: NotificationsInfoModel[]) => {
      if(responseData!=null){
        sessionStorage.removeItem('notification');
        sessionStorage.setItem('notifications', JSON.stringify(responseData));
        this.notificationCount= this.getUnreadNotificationsCount();
      }
      this.spinnerSrv.hide()
    }) 
  }
  getUnreadNotificationsCount() {

    if (sessionStorage.getItem('notifications') !== null) {
      const notifications: NotificationsInfoModel[] = JSON.parse(sessionStorage.getItem('notifications'));
      return notifications.filter(t => !t.notificationread).length;    
  }
  }

  onCloseNotification(){
    this.showNotificationPopup=false;
  }

  getLanguagefromSession(){
    var lang=this.localizationService.getlocalizationfromSession();
    switch(lang){
      case "english":
        this.selected1 = this.Langs.find(x=>x.languageId == 1).languageId;
        this.chatBot.CloseChatWindow();
       break;
      case "spanish":
        this.selected1 = this.Langs.find(x=>x.languageId == 2).languageId;
        this.chatBot.CloseChatWindow();
       break;
       default:
        this.selected1 = this.Langs.find(x=>x.languageId == 1).languageId;
       break;
    } 
  }
}

