export enum UserStatus {
    InvalidPass = -1,
    PasswordLockout = -2,
    NeedPassReset = -3,
    InvalidCaptcha = -5,
    valid = 1,
    invalid = 7,
    withdrawn = 6,
    inactive = 3,
    retired = 0,
    exception = 0,
    new= 1,
    newpc=5,
    TwoFactorAuthVerify = -6,
    InvalidOtp = -7,
    InvalidAttempt = -8
}