import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { SignInComponent } from './home/sign-in/sign-in.component';
import { RouterModule, Routes } from '@angular/router';
import { HeaderComponent } from './Common/header/header.component';
import { HaveQuestionsComponent } from './Common/have-questions/have-questions.component';
import { FooterComponent } from './Common/footer/footer.component';
import { HomeComponent } from './home/home/home.component';
import { WelcomeBladeComponent } from './home/welcome-blade/welcome-blade.component';
import { AboutScreenwiseComponent } from './home/about-screenwise/about-screenwise.component';
import { EarnRewardsComponent } from './home/earn-rewards/earn-rewards.component';
import { RedeemRewardsComponent } from './home/redeem-rewards/redeem-rewards.component';
import { GetStartedComponent } from './home/get-started/get-started.component';
import { DashboardBannerComponent } from './dashboard/dashboard-banner/dashboard-banner.component';
import { DevicesComponent } from './dashboard/devices/devices.component';
import { RewardsComponent } from './dashboard/rewards/rewards.component';
import { MyInfoDashboardComponent } from './dashboard/my-info-dashboard/my-info-dashboard.component';
import { HouseholdInfoComponent } from './dashboard/household-info/household-info.component';
import { ContactUsComponent } from './Common/contact-us/contact-us.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EnrollmentComponent } from './home/sign-in/enrollment/enrollment.component';
import { MyrewardsComponent } from './myRewards/myrewards/myrewards.component';
import { RewardsStatusComponent } from './myRewards/rewards-status/rewards-status.component';
import { RewardsRedeemComponent } from './myRewards/rewards-redeem/rewards-redeem.component';
import { RewardsSummaryComponent } from './myRewards/rewards-summary/rewards-summary.component';
import { RewardsEarningComponent } from './myRewards/rewards-earning/rewards-earning.component';
import { RewardsAlsoEarnComponent } from './myRewards/rewards-also-earn/rewards-also-earn.component';
import { RewardsInfoComponent } from './myRewards/rewards-info/rewards-info.component';
import { HeaderShowHideDirective } from './directive/headerShowHide.directive';
import { MyAddressInfoComponent } from './myProfile/my-address-info/my-address-info.component';
import { MyMeterAccountComponent } from './myProfile/my-meter-account/my-meter-account.component';
import { MyProfileComponent } from './myProfile/myprofile/myprofile.component';
import { MyInfoComponent } from './myProfile/my-info/my-info.component';
import { PolicyComponent } from './Common/Policies/policy/policy.component';
import { ChangePasswordComponent } from './myProfile/change-password/change-password.component';
import { ChangeNameOrEmailComponent } from './myProfile/change-name-or-email/change-name-or-email.component';
import { ChangeAddressComponent } from './myProfile/change-address/change-address.component';
import { ChangePhoneNumbersComponent } from './myProfile/change-phone-numbers/change-phone-numbers.component';
import { ForgotPasswordComponent } from './home/sign-in/forgot-password/forgot-password.component';
import { SharedEmailComponent } from './home/sign-in/shared-email/shared-email.component';
import { CheckEmailIdComponent } from './home/sign-in/check-email-id/check-email-id.component';
import { YourDevicesComponent } from './dashboard/your-devices/your-devices.component';
import { AddNewDeviceComponent } from './dashboard/add-new-device/add-new-device.component';
import { SowPanelHardwareUsageReturnAgreementComponent } from './Common/Policies/policy/sow_panel_hardware_usage_return_agreement/sow-panel-hardware-usage-return-agreement/sow-panel-hardware-usage-return-agreement.component';
import { PolicyHeaderComponent } from './Common/Policies/policy/sow_panel_hardware_usage_return_agreement/policy-header/policy-header.component';
import { PolicyContentComponent } from './Common/Policies/policy/sow_panel_hardware_usage_return_agreement/policy-content/policy-content.component';
import { IpsosSowPanelCookiePolicyComponent } from './Common/Policies/policy/ipsos_sow_panel_cookie_policy/ipsos-sow-panel-cookie-policy/ipsos-sow-panel-cookie-policy.component';
import { CookiePolicyHeaderComponent } from './Common/Policies/policy/ipsos_sow_panel_cookie_policy/cookie-policy-header/cookie-policy-header.component';
import { CookiePolicyContentComponent } from './Common/Policies/policy/ipsos_sow_panel_cookie_policy/cookie-policy-content/cookie-policy-content.component';
import { GooglePanelPrivacyPolicyComponent } from './Common/Policies/policy/google_panel_privacy_policy/google-panel-privacy-policy/google-panel-privacy-policy.component';
import { GooglePanelPrivacyPolicyConentComponent } from './Common/Policies/policy/google_panel_privacy_policy/google-panel-privacy-policy-conent/google-panel-privacy-policy-conent.component';
import { GooglePanelPrivacyPolicyHeaderComponent } from './Common/Policies/policy/google_panel_privacy_policy/google-panel-privacy-policy-header/google-panel-privacy-policy-header.component';
import { GooglePanelTermsConditionComponent } from './Common/Policies/policy/google_panel_terms_condition/google-panel-terms-condition/google-panel-terms-condition.component';
import { GooglePanelTermsConditionHeaderComponent } from './Common/Policies/policy/google_panel_terms_condition/google-panel-terms-condition-header/google-panel-terms-condition-header.component';
import { GooglePanelTermsConditionContentComponent } from './Common/Policies/policy/google_panel_terms_condition/google-panel-terms-condition-content/google-panel-terms-condition-content.component';
import { ChangeAddressSecondaryComponent } from './myProfile/change-address-secondary/change-address-secondary.component';
import { HelpCentreContentComponent } from './helpcentre/help-centre-content/help-centre-content.component';
import { HelpCentreQuestionModalComponent } from './helpcentre/help-centre-question-modal/help-centre-question-modal.component';
import { HelpCentreComponent } from './helpcentre/help-centre/help-centre.component';
import { HelpCentreHeaderComponent } from './helpcentre/help-centre-header/help-centre-header.component';
import { ImportantNotificationComponent } from './Common/important-notification/important-notification.component';
import { DonotSellInfoComponent } from './donot-sell-info/donot-sell-info.component';
import { ChangePhoneNumbersSecondaryComponent } from './myProfile/change-phone-numbers-secondary/change-phone-numbers-secondary.component';
import { MessagingComponent } from './messaging/messaging.component';
import { PoliciesLegalComponent } from './policies-legal/policies-legal.component';
import { IpsosSowPrivaryPolicyComponent } from './Common/Policies/policy/ipsos-sow-privary-policy/ipsos-sow-privary-policy.component';
import { IpsosSowPrivaryPolicyHeaderComponent } from './Common/Policies/policy/ipsos-sow-privary-policy/ipsos-sow-privary-policy-header/ipsos-sow-privary-policy-header.component';
import { IpsosSowPrivaryPolicyContentComponent } from './Common/Policies/policy/ipsos-sow-privary-policy/ipsos-sow-privary-policy-content/ipsos-sow-privary-policy-content.component';
import { TransientPageLevelNotificationComponent } from './transient-page-level-notification/transient-page-level-notification.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
//import { environment } from  '../environments/environment';
import { APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { SignoutHeaderComponent } from './myprofile/signout/signout-header/signout-header.component';
import { SignoutComponent } from './myprofile/signout/signout/signout.component';
import { AddressInfoData } from './model/addressInfoData.model';
import { ProfilePhoneNumber } from './model/phone-number.model';
import { MemberPersonalInfo } from './model/memberPersonalInfo.model';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ModalModule } from './_modal';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AutoMoveNextDirective } from './directive/auto-move-next.directive';
import { AppGlobals } from './model/app.global';
import { PopupComponent } from './Common/popup/popup.component';
import { MatDialogModule } from '@angular/material/dialog';
import { McafeeComponent } from './Common/mcafee/mcafee.component';
import { ResetpasswordComponent } from './home/sign-in/resetpassword/resetpassword.component';
import { TooltipModule } from 'ng2-tooltip-directive';
import { UserIdleModule } from 'angular-user-idle';
import { PasswordValidatorDirective } from './directive/password.directive';
import { EmailValidatorDirective } from './directive/email.directive';
import { DatePipe } from '@angular/common';
import { PasswordPolicyDirective } from './directive/password-policy.directive';
import { AuthGuard } from './services/auth.guard';
import { BsDatepickerModule} from 'ngx-bootstrap/datepicker';  
import { RestrictAuthGuard } from './services/restrict.auth.guard';
import { AgreeComponent } from './home/sign-in/agree/agree.component';
import { JoinstudyclosedComponent } from './Common/joinstudyclosed/joinstudyclosed.component';
import { ErrorComponent } from './home/error/error.component';
import { TeenConsentComponent } from './Common/teen-consent/teen-consent.component';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { EnrollmentIncentiveComponent } from './enrollment-incentive/enrollment-incentive.component';
import { IncentiveErrorComponent } from './incentive-error/incentive-error.component';
import { ScreenwisemeterComponent } from './dashboard/screenwisemeter/screenwisemeter.component';
import { HowYouCanEarnComponent } from './myRewards/how-you-can-earn/how-you-can-earn.component';
import { JwtInterceptorService } from './services/jwt-interceptor.service';
import { SignInService } from './services/signin.service';
import { RewardsHistoryComponent } from './myRewards/rewards-history/rewards-history.component';
import {  ViewHelpCenterPopupComponent } from './Common/contact-us/viewhelpcenterpopup/viewhelpcenterpopup.component';
import { GenericerrorComponent } from './common/genericerror/genericerror.component';
import { ChatbotComponent } from './Common/chatbot/chatbot.component';
import { DownloadMeterNotificationComponent } from './Common/download-meter-notification/download-meter-notification.component';
import { AppConfigService } from './Config/AppConfigService';
import { AppConfig } from './Config/AppConfig';
import { SetPasswordComponent } from './home/sign-in/set-password/set-password.component';
import { SurveyCompletedComponent } from './home/survey-completed/survey-completed.component';
import { AlertPopupComponent } from './Common/alert-popup/alert-popup.component';

export function jsonConfigInitializer(appConfigSvc: AppConfigService) {
  return () => {
    return appConfigSvc.loadJsonConfig();
  }
}


const appRoutes:Routes = [
  {path:'',component:HomeComponent,canActivate: [RestrictAuthGuard]},
  {path:'home',component:HomeComponent,canActivate: [RestrictAuthGuard]},
  {path:'dashboard',component:DashboardBannerComponent,canActivate: [AuthGuard],},
  {path: 'home/welcome', component: HomeComponent, canActivate: [RestrictAuthGuard]},
  { path: 'home/account', component: HomeComponent, canActivate: [RestrictAuthGuard] },
  {path:'contact-us',component:ContactUsComponent},
  { path: 'enroll', component: EnrollmentComponent, children: [
    { path: 'es', component: EnrollmentComponent }
  ]},
  {path:'enroll/:neocode',component:EnrollmentComponent},
  {path:'SurveyCompleted', component: SurveyCompletedComponent,canActivate: [RestrictAuthGuard]},
  {path:'setpassword', component: SetPasswordComponent,canActivate: [RestrictAuthGuard]},
  {path:'myrewards',component:MyrewardsComponent,canActivate: [AuthGuard]},
  {path:'myprofile',component:MyProfileComponent,canActivate: [AuthGuard]},
  {path:'helpcenter',component:HelpCentreComponent},  
  {path:'changepassword',component:ChangePasswordComponent,canActivate: [RestrictAuthGuard,AuthGuard]},
  {path:'change-name-email',component:ChangeNameOrEmailComponent},
  {path:'change-address',component:ChangeAddressComponent},
  {path:'change-phone-number',component:ChangePhoneNumbersComponent},
  {path:'hardware-policy',component:SowPanelHardwareUsageReturnAgreementComponent},
  {path:'cookie-policy',component:IpsosSowPanelCookiePolicyComponent},
  {path:'google-panel-privacy-policy',component:GooglePanelPrivacyPolicyComponent},
  {path:'google-panel-terms-condition',component:GooglePanelTermsConditionComponent},
  {path:'donot-sell-info',component:DonotSellInfoComponent},
  {path:'import-notification',component:ImportantNotificationComponent},
  {path:'header',component:HeaderComponent},
  {path:'policies-legal',component:PoliciesLegalComponent},
  {path:'ipsos-Sow-privacy-policy',component:IpsosSowPrivaryPolicyComponent},  
  {path:'trasient-pagelevel-notification',component:TransientPageLevelNotificationComponent},
  {path: 'helpcentermodal', component: HelpCentreQuestionModalComponent},
  {path: 'signout' , component: SignoutComponent,canActivate: [RestrictAuthGuard]},
  {path: 'privacyPolicy', component: GooglePanelPrivacyPolicyComponent},
  {path: 'signin', component: SignInComponent,canActivate: [RestrictAuthGuard]},
  {path: 'helpcenterModal', component : HelpCentreQuestionModalComponent },
  {path:'forgotpassword',component:ForgotPasswordComponent},
  {path:'resetpassword',component:ResetpasswordComponent},
  {path:'rp',component:ResetpasswordComponent,data:{title:"Reset Password: Screenwise"}},
  {path:'agree',component:AgreeComponent},
  {path:'joinstudyclosed.html',component:JoinstudyclosedComponent},
  {path:'eredeem/:p1/:p2/:p3',component:EnrollmentIncentiveComponent},
  {path:'credeem/:p1/:p2/:p3',component:EnrollmentIncentiveComponent},
  {path:'error.html',component:ErrorComponent},
  {path:'enrollqr',component:EnrollmentComponent},
  {path:'errorpage.html',component:IncentiveErrorComponent},
  {path:'genericerrorpage.html',component:GenericerrorComponent},
  {path:'**', redirectTo:'home'}
];

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    HeaderComponent,
    FooterComponent,
    HaveQuestionsComponent,
    HomeComponent,
    WelcomeBladeComponent,
    AboutScreenwiseComponent,
    EarnRewardsComponent,
    RedeemRewardsComponent,
    GetStartedComponent,
    DashboardBannerComponent,
    DevicesComponent,
    RewardsComponent,
    MyInfoDashboardComponent,
    HouseholdInfoComponent,
    ContactUsComponent,
    EnrollmentComponent,
    MyrewardsComponent,
    RewardsStatusComponent,
    RewardsRedeemComponent,
    RewardsSummaryComponent,
    RewardsEarningComponent,
    RewardsAlsoEarnComponent,
    RewardsInfoComponent,
    HeaderShowHideDirective,
    MyAddressInfoComponent,
    MyMeterAccountComponent,
    MyProfileComponent,
    MyInfoComponent,
    PolicyComponent,
    ChangePasswordComponent,
    ChangeNameOrEmailComponent,
    ChangeAddressComponent,
    ChangePhoneNumbersComponent,
    ForgotPasswordComponent,
    SharedEmailComponent,
    CheckEmailIdComponent,
    YourDevicesComponent,
    AddNewDeviceComponent,
    SowPanelHardwareUsageReturnAgreementComponent,
    PolicyHeaderComponent,
    PolicyContentComponent,
    IpsosSowPanelCookiePolicyComponent,
    CookiePolicyHeaderComponent,
    CookiePolicyContentComponent,
    GooglePanelPrivacyPolicyComponent,
    GooglePanelPrivacyPolicyConentComponent,
    GooglePanelPrivacyPolicyHeaderComponent,
    GooglePanelTermsConditionComponent,
    GooglePanelTermsConditionHeaderComponent,
    GooglePanelTermsConditionContentComponent,
    ChangeAddressSecondaryComponent,
    HelpCentreContentComponent,
    HelpCentreQuestionModalComponent,
    HelpCentreComponent,
    HelpCentreHeaderComponent,
    ImportantNotificationComponent,
    DonotSellInfoComponent,
    ChangePhoneNumbersSecondaryComponent,
    MessagingComponent,
    PoliciesLegalComponent,
    IpsosSowPrivaryPolicyComponent,
    IpsosSowPrivaryPolicyHeaderComponent,
    IpsosSowPrivaryPolicyContentComponent,
    TransientPageLevelNotificationComponent,
    SignoutHeaderComponent,
    SignoutComponent,
    AutoMoveNextDirective,
    PopupComponent,
    McafeeComponent,
    ResetpasswordComponent,
    PasswordValidatorDirective,
    EmailValidatorDirective,
    PasswordPolicyDirective,
    AgreeComponent,
    JoinstudyclosedComponent,
    ErrorComponent,
    TeenConsentComponent,
    EnrollmentIncentiveComponent,
    IncentiveErrorComponent,
    ScreenwisemeterComponent,
    HowYouCanEarnComponent,
    RewardsHistoryComponent,
    ViewHelpCenterPopupComponent,
    GenericerrorComponent,
    ChatbotComponent,
    DownloadMeterNotificationComponent,
    SetPasswordComponent,
    SurveyCompletedComponent,
    AlertPopupComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes),
    FormsModule,
    BrowserAnimationsModule,
    MatExpansionModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    HttpClientModule,
    NgxCaptchaModule,
    ModalModule,
    NgxSpinnerModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    TooltipModule,
    UserIdleModule,
    BsDatepickerModule.forRoot(),
    NgxGoogleAnalyticsModule,
    NgxGoogleAnalyticsRouterModule
  ],
  providers: [
    HttpClientModule,
    AddressInfoData,
    ProfilePhoneNumber,
    MemberPersonalInfo,
    UserIdleModule,
    AppGlobals,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptorService,
      deps:[SignInService],
      multi: true  
    },
    {
      provide: AppConfig,
      deps: [ HttpClient ],
      useExisting: AppConfigService,
    }, 
    {
      provide: APP_INITIALIZER,
      deps: [ AppConfigService ],
      multi: true,
      useFactory: jsonConfigInitializer
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
