<section class="messaging signed-out" *ngIf="showValidationMessages" id="messageDisplay">
  <div class="container">
    <div class="messaging__close" (click)="onClose(signin)">
      <img src="assets/images/icons/icon_close.svg" alt="" />
    </div>
    <div class="messaging__body">
      <h3 *ngIf="isUserInvalid">{{localModel.lbl_Login_UserNPassValid}}</h3>
      <h3 *ngIf="isUserRetired">{{localModel.lbl_signin_valid_1}}</h3>
      <h3 *ngIf="isUserWithdrawn">{{localModel.lbl_signin_valid_2}}</h3>
      <h3 *ngIf="isAccountLocked">{{lockoutMsg}}</h3>
      <h3 *ngIf="isInvalidCaptcha">{{localModel.lbl_login_CaptchaMsg}}</h3>
    </div>
    <div class="messaging__actions">
      <button class="btn btn__primary" (click)="onClose(signin)">
        <img src="assets/images/icons/icon_check.svg" alt="" />
        {{localModel.btn_Notification_One}}
      </button>
    </div>
  </div>
</section>
<section class="messaging signed-out" *ngIf="showLinkExpired">
  <div class="container">
    <div class="messaging__close" (click)="onLinkExpiredClose()">
      <img src="assets/images/icons/icon_close.svg" alt="" />
    </div>
    <div class="messaging__body">
      <h3 *ngIf="isLinkInvalid" [innerHTML]="localModel.lbl_LinkExpiredContactSupport_Msg"></h3>
    </div>
    <div class="messaging__actions">
      <button class="btn btn__primary" (click)="onLinkExpiredClose()">
        <img src="assets/images/icons/icon_check.svg" alt="" />
        {{localModel.btn_Notification_One}}
      </button>
    </div>
  </div>
</section>
<section class="sign-in-main">
  <div class="container get-started-prompt">
    <h2 [innerHtml]="localModel.MsgLbl1_sign_in">

    </h2>
    <div class="deemphasize" [innerHtml]="localModel.MsgLbl2_sign_in"></div>
    <a class="btn btn__secondary btn--get-started" (click)="onEnrollment()">{{localModel.BtnLbl_sign_in_getstarted}}

    </a>
  </div>
  <div class="container container--fluid">
    <div class="container--form">
      <h1 [innerHtml]="localModel.BtnLbl_sign_in_signin"></h1>

      <form class="form" (ngSubmit)="onSubmit(signin)" #signin="ngForm">
        <div *ngIf="!otpRequired">

          <div class="form-control">
            <label for="email" class="form-control__label" [innerHtml]="localModel.Lbl_sign_in_email"></label>
            <input type="email" class="form-control__text" [(ngModel)]="username" #email='ngModel' name="email" required
              email />
            <span *ngIf="!submitted && !email.valid && email.touched">{{localModel.lbl_signin_valid_3}}</span>
            <span *ngIf="submitted && !email.valid">{{localModel.lbl_signin_valid_3}}</span>

          </div>

          <div class="form-control">
            <label for="password" class="form-control__label" [innerHtml]="localModel.Lbl_sign_in_password"></label>
            <div class="password-wrapper">
              <input [type]="show ? 'text':'password'" id="password" ngModel name="password" required
                #password="ngModel" required />
              <button type="button" class="toggle-password" (click)="onbtnClick()">
                <img
                  [src]="show ? 'assets/images/icons/icon_hide_password.svg' : 'assets/images/icons/icon_show_password.svg'"
                  alt="" class="toggle-password__img" />
              </button>
            </div>
            <span class=".form-control__error-text" *ngIf="!submitted && !password.valid && password.touched"
              [innerHtml]="localModel.Lbl_sign_in_password_validation">
            </span>
            <span class=".form-control__error-text" *ngIf="submitted && !password.valid"
              [innerHtml]="localModel.Lbl_sign_in_password_validation">
            </span>

            <div class="small text-right ">
              <a class="modal-link forgot-password" (click)="onForgotPassword()"
                [innerHtml]="localModel.Lbl_sign_in_forgotpass"></a>
            </div>
          </div>
          <div class="g-recaptcha" style="margin: 0 auto;display: table;">
            <ngx-recaptcha2 #captchaElem [siteKey]="sitekey" (success)="handleSuccess($event)" [size]="size" [hl]="lang"
              (load)="handleReload()" [theme]="theme" [type]="type" name="recaptcha" ngModel>
            </ngx-recaptcha2>
          </div>
          <br />
          <div class="form-control custom-checkbox">
            <input id="rememberMe" [(ngModel)]="isRememberChecked" [ngModelOptions]="{standalone: true}"
              type="checkbox" />
            <label for="rememberMe" class="custom-checkbox__label"
              [innerHtml]="localModel.Lbl_sign_in_remberme"></label>
          </div>
          <button type="submit" class="btn btn__primary" [innerHtml]="localModel.BtnLbl_sign_in_signin"></button>
        </div>
        <div *ngIf="otpRequired">
          <div class="headerLabel"><label for="uname">{{localModel.lbl_Otp_Message}}</label></div>
          <div class="headerLabel"><label for="otp">{{localModel.lbl_codeHeader_Message}}</label></div>
          <div class="headerLabel" *ngIf="invalidOtp"><label class="text-danger"
              for="uname">{{localModel.lbl_invalidOtp_Message}}</label>
          </div>
          <div class="headerLabel">
            <input type="text" id="Otp" [(ngModel)]="enteredOtp" #Otp='ngModel' name="Otp"
              (keypress)="onKeyPress($event)" [ngClass]="{ 'is-invalid': isInvalidOtp || !isSubmitbtnEnabled }"
              (input)="onInput($event.target.value)" maxlength="6">
            <div class="headerLabel"><span *ngIf="!isSubmitbtnEnabled"
                class="mandatory">{{localModel.lbl_otpValidation_Message}}</span></div>
          </div>
          <div class="headerLabel" *ngIf="resendTimer">
            <label class="labelHeader" for="uname">{{resendMessage}}</label>
          </div>
          <div class="headerLabel" *ngIf="!resendTimer && !invalidOtp">
            <label for="uname">{{localModel.lbl_noResponse_Message}}</label>
          </div>
          <div class="headerLabel">
            <button class="btn" type="submit" [disabled]="!isSubmitbtnEnabled"
              [ngClass]="isSubmitbtnEnabled ? 'btn-enabled' : 'btn-disabled'">{{localModel.lbl_submitOtp_Message}}
            </button>
          </div>
          <div class="headerLabel">
            <button [disabled]="!resendDisabled" class="btn btn-secondary setbtn btn" type="button"
              (click)="setButtonType('Resend');onSubmit()"
              [ngClass]="resendDisabled ? 'btn-enabled' : 'btn-disabled' ">{{localModel.lbl_ResendCodeBtnLabel}}
            </button>
          </div>
          <div class="headerLabel">
            <span class="help-label center-label">
              {{localModel.lbl_NeedHelp_Title}}
            </span>
          </div>
        </div>
      </form>
    </div>

    <img src="assets/images/illustrations/couch_with_devices.svg" alt="" />
  </div>


</section>
<app-forgot-password *ngIf="showForgotPassword" (closeForgorPassword)="onClose()"></app-forgot-password>
<app-shared-email *ngIf="isShowSharedEmail" (closeSharedEmail)="onCloseSharedEmail()"></app-shared-email>
<app-check-email-id *ngIf="isShowCheckEmailID" (closeCheckEmailId)="onCloseCheckEmailId()"></app-check-email-id>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.4)"></ngx-spinner>