<!-- notification section -->
<app-important-notification *ngIf="showNotificationPopup" [header]="notificationHeader" [content]="sanitizedNotificationMsg"
    [showExtraButton]="showExtraNotificationBtn"
    (closeNotification)="onCloseNotification()">  
</app-important-notification>

<!-- Header -->
<header class="header" id="navHeader" appHeaderShowHide>
    <div class="logo">
      <a class="modal-link" (click)="checkRoute()">
        <img src="assets/images/logos/SCREENWISE COLOR.svg" alt="" />
      </a>
    </div>
    <div class="header__utilities">
      <nav class="nav">
        <a class="mobile-menu" (click)="clickEvent()" [ngClass]="moblieMenu ? 'open' : ''" >
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>      
        </a>
        <ul id="mainMenu" [ngClass]="moblieMenu ? 'open' : ''">
          <li >
            <img src="assets/images/logos/SCREENWISE COLOR.svg" alt="" class="logo__mobile-menu" />
          </li>
          <li *ngIf="!isUserSignIn"><a routerLink="/home" class="home" routerLinkActive="selected" (click)="clickEvent()" [innerHtml]="localModel.lbl_header_home"></a></li>
          <li *ngIf="isUserSignIn" class="first"><a routerLink="/dashboard" routerLinkActive="selected" (click)="clickEvent()" [innerHtml]="localModel.Lbl_header_mydashTitle"></a></li>
          <li *ngIf="isUserSignIn"><a routerLink="/myrewards" routerLinkActive="selected" (click)="clickEvent()" [innerHtml]="localModel.Lbl_header_myrewaTitle"></a></li>
          <li *ngIf="isUserSignIn"><a routerLink="/myprofile" routerLinkActive="selected" (click)="clickEvent()" [innerHtml]="localModel.Lbl_footer_myprofileTitle"></a></li>
          <li *ngIf="isUserSignIn"><a routerLink="/helpcenter" routerLinkActive="selected" (click)="clickEvent()" [innerHtml]="localModel.Lbl_header_hlpcntrTitle"></a></li>
          <li><a routerLink="/contact-us" routerLinkActive="selected" (click)="clickEvent()" [innerHtml]="localModel.Lbl_header_contactTitle"></a></li>
          <div class="language-selector-mobile" *ngIf="isUserSignIn">
            <select [(ngModel)]="selected1" name="lang" placeholder="select" (ngModelChange)="onLanguageChange($event)">
              <option class="option"*ngFor="let l of Langs" [ngValue]="l.languageId">{{l.languageName}}
              </option>
          </select>
          </div>
          <li> 
        </li>

        </ul>
      </nav>
      <div class="language-selector">
        <select [(ngModel)]="selected1" name="lang" placeholder="select" (ngModelChange)="onLanguageChange($event)">
                    <option *ngFor="let l of Langs" [ngValue]="l.languageId">{{l.languageName}}
                    </option>
                </select>
      </div>
  
    
      <div *ngIf="isUserSignIn" class="notifications"> 
        <span class=notificationscounter>{{notificationCount}}</span>
        <a class="icon--utility" (click)="onImportNofication()">
          <img src="assets/images/icons/icon_notification_active.svg" *ngIf="loadingImg1" alt="Notifications1" class="loadingimage"/>
          <img src="assets/images/icons/icon_notification.svg" alt="Notifications"  *ngIf="loadingImg" />
        </a>
      </div>
      <div class="d-none d-sm-none d-md-block notification_dd" style="border-radius: 10px;" *ngIf="togglenotification">
        <div class="row" *ngIf="unreadNotifications.length>0">
          <div class="seeAll" style="border-radius: 10px 10px 0px 0px;" >{{localModel.lblNotificationsTitle}}</div>
          <div style="overflow-y: auto; max-height: 300px;">
            <div class="col-md-12" style="margin: 2px 0px;"*ngFor="let notification of unreadNotifications">
               
              <div class="message-box-success" [ngClass]="{
                'message-box-read': notification.notificationread==1
              }">
                <a (click)="openNotificationMsg(notification.msgmbrlistid)"
                >{{ notification.msgtitle }}
                </a>
              </div>
            </div>
          </div>  
          
            </div>
              <div class="row" *ngIf="unreadNotifications.length==0" style="overflow-y: auto; height: 100%; margin-top: 20px;font-family:Arial, Helvetica, sans-serif;">
                  <div class="col-md-12" style="padding-bottom: 25px; text-align: center;">
                 {{localModel.lbl_ShowNoNotificationMessage}}
                  </div>    
                </div>
      </div>
 
      <div *ngIf="!isUserSignIn" class="signin">
        <a routerLink="/home" [innerHtml]="localModel.lbl_header_signin"></a>
      </div>
      <div *ngIf="isUserSignIn" class="profile">
        <button type="button" class="btn btn__icon" (click)="onProfile()" id="btnProfile">
          <span class="icon--utility">
            <img src="assets/images/icons/icon_profile.svg" alt="Profile Menu" />
          </span>
          <img src="assets/images/icons/icon_dropdown.svg" class="profile__dropdown-icon" [ngClass]="toggle ? 'expanded' : '' " alt="" />
        </button>
        <div class="profile-menu" [ngClass]="toggle ? 'open' : '' " id="profileMenu" #profileMenu>
            <div class="profile__user">
                {{localModel.lbl_header_signin_1}}
               <strong *ngIf="userData != null">{{userData.FirstName}} {{userData.LastName}}</strong>
            </div>
          <ul class="profile__menu-items">
            <li (click)="onMyProfile()">
              <a>
                <img src="assets/images/icons/icon_signout.svg" alt="" />
                <div [innerHtml]="localModel.Lbl_header_profileTitle"></div>
                </a
              >

            </li>
            <li (click) = "onSignOut()">
              <a>
                <img src="assets/images/icons/icon_my_profile.svg" alt=""  />
                <div [innerHtml]="localModel.Lbl_header_signoutTitle"></div>
                </a>
            </li>
          </ul>
        </div>
      </div>

    </div>
  </header>

  <!-- Logo: For Home Page - Mobile Only -->
  <div class="text-center mobile-only logo-home" *ngIf="!isUserSignIn">
    <img src="assets/images/logos/SCREENWISE COLOR.svg" alt="" class="logo__mobile-menu" />  
  </div>
  <!-- loading spinner -->
<ngx-spinner bdColor="rgba(51, 51, 51, 0.4)"></ngx-spinner>

<div *ngIf="isUserSignIn" class="pos-absolute">
  <app-chatbot></app-chatbot>
</div>
